.root {
  @apply overflow-hidden;
}

.ticker {
  @apply overflow-hidden;
}

.scroller {
  @apply flex items-center h-8;
}

.items {
  @apply flex items-center flex-shrink-0 justify-around whitespace-nowrap relative gap-48 pl-48 md:gap-96 md:pl-96;
}

.ticker.stationary .scroller {
  @apply justify-center;
}

.ticker.stationary .items {
  @apply gap-0 pl-0;
}

.ticker.stationary .items:not(.original) {
  @apply hidden;
}

.ticker.animated .items {
  animation: ticker calc(1s * var(--ticker-speed)) linear infinite;
  animation-play-state: running;
}

.ticker.animated:hover .items {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
}
