.wrapper[data-disabled='true'] {
  @apply opacity-50 cursor-default;
}

/* Align */

.wrapper[data-align='center'] {
  @apply justify-items-center items-center;
}

.wrapper[data-align='top'] {
  @apply justify-items-center items-start;
}

label.wrapper {
  @apply cursor-pointer;
}

/* Sizes */

.wrapper[data-size='normal'] .input {
  width: 1em; /* Using `em` to keep it relative to the `<label>` font-size */
  height: 1em;
}

.wrapper[data-size='normal'] .control {
  width: 1em; /* Using `em` to keep it relative to the `<label>` font-size */
  height: 1em;
}

.wrapper[data-size='large'] .input {
  width: 1.25em; /* Using `em` to keep it relative to the `<label>` font-size */
  min-width: 1.25em;
  height: 1.25em;
  @apply mr-2.5 md:mr-4;
}

.wrapper[data-size='large'] .control {
  width: 1.25em; /* Using `em` to keep it relative to the `<label>` font-size */
  height: 1.25em;
}

.wrapper[data-error='false'] .input:hover:not(:disabled) + .control {
  @apply border-green-500;
}

.input:checked + .control {
  @apply border-green-500 text-white bg-green-500;
}

.input:checked + .control > .icon {
  @apply opacity-100;
}

.wrapper[data-error='true'] .control,
.wrapper[data-error='true'] input:checked + .control {
  @apply border-red-800 text-red-800;
}

.input:focus-visible + .control::after {
  @apply block -inset-6 absolute border-primary-800 border-2;
  content: '';
}
