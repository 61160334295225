.button {
  @apply relative text-gray-800 opacity-100 cursor-pointer;
}

.button:before {
  @apply rounded-full;
}

.button[aria-disabled='true'] {
  @apply text-gray-500 opacity-50 cursor-default;
}

.button[data-size='small'] {
  @apply w-5 h-5;
}

.button[data-size='large'] {
  @apply w-10 h-10;
}

@media (max-width: theme('screens.lg')) {
  .button:after {
    content: '';
    @apply absolute w-10 h-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
  }
}

.input {
  @apply flex justify-center w-4 mx-2 text-center text-gray-900 pointer-events-none autoType300 md:w-6;
  -moz-appearance: textfield;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}
