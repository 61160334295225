.footer {
  @apply bg-green-800;
  grid-area: footer;
  position: relative;
}

.footer[data-visible='false'] {
  display: none;
}

.socialIcon {
  @apply w-6 h-6;
}

.socialIcon > g > path {
  @apply fill-canvas-100;
}

.socialIcon > path {
  @apply fill-canvas-100;
}
