.popupRoot {
  pointer-events: none;
}

.popupPaper, .newNavPopupPaper {
  pointer-events: auto;
  overflow: visible !important;
}

.newNavPopupPaper {
  position: relative;
}

@screen md {
  /**
   * We can't use tailwind's @apply here since it doesn't work well as MUI's classes.
   * Popup sets styles in [style], so some !important are necessary.
   */
  .popupPaper {
    border-left: 1px solid rgb(239.7, 239.7, 239.7) !important;
    left: 50% !important;
    padding: 1.5rem 2rem;
    top: 4.75rem !important;
    overflow: hidden !important;
    overflow-y: auto !important;
  }
}
