.button {
  @apply relative;
}

.button:before {
  @apply border-none;
}

.button[data-variant*='outlined']:before {
  @apply -inset-6 !important;
}

/* Rectangle Button */

.button[data-shape='rectangle'] {
  @apply justify-center;
}

.button[data-shape='rectangle'] .content {
  @apply grid items-center grid-flow-col gap-1 w-full;
}

/* Sizes */

.button[data-shape='rectangle'][data-size='large'] {
  @apply px-10 py-4 autoType600;
}

.button[data-shape='rectangle'][data-size='normal'] {
  @apply text-base py-3 px-6;
}

.button[data-shape='rectangle'][data-size='small'] {
  @apply px-5 py-3 autoType400;
}

.button[data-shape='rectangle'][data-size='xsmall'] {
  @apply p-3 autoType400;
}

.button[data-shape='wide'][data-size='xsmall'] {
  @apply py-3 px-6 autoType400;
}

.button[data-shape='rectangle'][data-size='xxsmall'] {
  @apply autoType300 py-1 px-4 h-9;
}

.button[data-shape='pill'][data-size='large'],
.button[data-shape='pill'][data-size='normal'],
.button[data-shape='pill'][data-size='small'],
.button[data-shape='pill'][data-size='xsmall'],
.button[data-shape='pill'][data-size='xxsmall'] {
  @apply px-6 py-4 rounded-full autoType400;
}

.button[data-shape='pill'] .content {
  @apply grid items-center grid-flow-col gap-1 w-full;
}

/* This should fix buttons that have text and icon getting looking weird when w-full */
@media (min-width: 768px) {
  .button[data-shape='pill'] .content {
    justify-content: unset;
  }
}

/* Primary Contained */

.button[data-variant='primary-contained'] {
  @apply border-2 border-solid;
  border-color: var(--bf-temp-button-background-color-primary-active);
  background-color: var(--bf-temp-button-background-color-primary-active);
  color: var(--bf-temp-button-text-color-primary-active);
}

.button[data-variant='primary-contained']:hover {
  border-color: var(--bf-temp-button-background-color-primary-hover);
  background-color: var(--bf-temp-button-background-color-primary-hover);
  color: var(--bf-temp-button-text-color-primary-active);
}

.button[data-variant='primary-contained']:disabled {
  border-color: var(--bf-temp-button-background-color-primary-inactive);
  background-color: var(--bf-temp-button-background-color-primary-inactive);
  color: var(--bf-temp-button-text-color-primary-inactive);
}

/* Primary Outline */

.button[data-variant='primary-outlined'] {
  @apply text-gray-850 border-2 border-gray-850 border-solid;
}

.button[data-variant='primary-outlined']:disabled {
  @apply text-gray-310 !important;
  @apply border-gray-310 !important;
}

/* Light Primary Contained */

.button[data-variant='light-primary-contained'] {
  @apply text-gray-800 bg-primary-100 border-none;
}

.button[data-variant='light-primary-contained']:hover {
  @apply text-primary-contrast bg-primary-900;
}

/* Light Primary Outline */

.button[data-variant='light-primary-outlined'] {
  @apply text-gray-800 border-2 border-primary-100 border-solid;
}

.button[data-variant='light-primary-outlined']:hover {
  @apply text-gray-800 border-primary-800;
}

/* Dark Primary Contained */
.button[data-variant='dark-primary-contained'] {
  @apply text-white bg-gray-850 border-none;
}

.button[data-variant='dark-primary-contained']:hover {
  @apply opacity-80;
}

/* Gray Outlined */

.button[data-variant='gray-outlined'] {
  @apply border border-gray-300;
}

/* Dark Gray Outlined */

.button[data-variant='dark-gray-outlined'] {
  @apply border-2 border-gray-500;
}

/* White Contained */

.button[data-variant='white-contained'] {
  @apply text-gray-800 bg-white border-none;
}

.button[data-variant='white-contained']:hover {
  @apply text-gray-800;
}

/* White Outlined */

.button[data-variant='white-outlined'] {
  @apply text-white border-2 border-white;
}

/* Red Contained */

.button[data-variant='red-contained'] {
  @apply text-white bg-red-800 border-2 border-red-800 border-solid;
}

.button[data-variant='red-contained']:hover {
  @apply bg-red-800;
}

/* Red Outlined */

.button[data-variant='red-outlined'] {
  @apply text-red-800 border-2 border-red-800 border-solid;
}

.button[data-variant='red-outlined']:hover {
  @apply text-red-800 border-red-800;
}

/* Red Text */

.button[data-variant='red-text'] {
  @apply text-red-800 border-none font-medium underline;
}

.button[data-variant='red-text']:hover {
  @apply no-underline;
}

/*Borderless */

.button[data-variant='borderless']:disabled,
.button[data-variant='borderless'][data-state='disabled'] {
  @apply text-gray-500;
}

/* Disabled State */

.button[data-variant*='contained']:disabled {
  @apply pointer-events-none;
}

.button[data-variant*='contained']:not([data-variant='primary-contained']):not([data-variant='primary-outlined']):disabled,
.button[data-variant*='contained'][data-state='disabled'] {
  @apply opacity-20;
}

/* TODO: Change this variant's disabled styling when designer rethink  */

.button[data-variant*='outlined']:disabled,
.button[data-variant*='outlined'][data-state='disabled'] {
  @apply text-gray-500 border-gray-500 cursor-not-allowed;
}

/* Circle Button */

.button[data-shape='circle'] {
  @apply inline-flex items-center justify-center rounded-full;
}

.button[data-shape='circle'] .content {
  @apply block;
}

/* Sizes */

.button[data-shape='circle'][data-variant='white-contained'] {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.button[data-shape='circle'][data-variant='white-contained']:hover {
  transform: scale(1.1);
}

.button[data-shape='circle'][data-size='large'] {
  @apply w-16 h-16;
}

.button[data-shape='circle'][data-size='normal'] {
  @apply w-12 h-12;
}

.button[data-shape='circle'][data-size='small'] {
  @apply w-10 h-10;
}

.button[data-shape='circle'][data-size='xsmall'] {
  @apply w-5 h-5;
}

/* Square Button */

.button[data-shape='square'] {
  @apply inline-flex items-center justify-center;
}

.button[data-shape='square'] .content {
  @apply block;
}

/* Sizes */

.button[data-shape='square'][data-variant='white-contained'] {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
}

.button[data-shape='square'][data-variant='white-contained']:hover {
  transform: scale(1.1);
}

.button[data-shape='square'][data-size='large'] {
  @apply w-16 h-16;
}

.button[data-shape='square'][data-size='medium'] {
  @apply w-14 h-14;
}

.button[data-shape='square'][data-size='normal'] {
  @apply w-12 h-12;
}

.button[data-shape='square'][data-size='small'] {
  @apply w-10 h-10;
}

.button[data-shape='square'][data-size='xsmall'] {
  @apply w-5 h-5;
}

/* Loading State */

.button[data-loading='true'],
.button[data-loading='true'][data-state='disabled'] {
  @apply pointer-events-none transition-none;
}

.button[data-loading='true'] .content,
.button[data-loading='true'][data-state='disabled'] .content {
  color: transparent;
}

.button[data-loading='true'][data-variant='primary-contained'] svg {
  @apply text-gray-50;
}

.button[data-loading='true'][data-variant='primary-outlined'] svg {
  @apply text-gray-50;
}

.button[data-loading='true'][data-variant='dark-primary-contained'] svg {
  @apply text-gray-850;
}

.button[data-loading='true'][data-variant='dark-gray-outlined'] svg {
  @apply text-gray-310;
}

.button[data-loading='true'][data-variant='red-contained'] svg {
  @apply text-white;
}

.button[data-loading='true'][data-variant='red-outlined'] svg {
  @apply text-red-800;
}

.button[data-loading='true'][data-variant='white-outlined'] svg {
  @apply text-white;
}

.button[data-loading='true'][data-state='disabled'] svg {
  @apply text-gray-500;
}

/* Jungle Contained */

.button[data-variant='jungle-contained'] {
  @apply font-medium text-green-500 bg-transparent border-2 border-green-500 border-solid;
}

.button[data-variant='jungle-contained']:hover {
  @apply opacity-80;
}

.button[data-variant='jungle-contained']:disabled {
  @apply opacity-20;
}

/* Jungle Outline */

.button[data-variant='jungle-outlined'] {
  @apply text-gray-900 border-2 border-gray-310 border-solid font-normal;
}