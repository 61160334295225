/* Types */

.link {
  @apply text-gray-800 font-medium text autoType300 underline-offset-2;
}

.link[data-variant='noUnderlineHover'] {
  @apply underline hover:no-underline;
}

.link[data-variant='noUnderline'] {
  @apply no-underline;
}

.link[data-variant='underlineHover'] {
  @apply hover:underline;
}

.link[data-variant='disabled'] {
  @apply pointer-events-none;
}

.link[data-variant='disabled'] .content {
  color: #cfcec9;
  @apply no-underline;
}
