.container {
  @apply px-6 pt-6 text-center;
  @apply lg:px-12;
}

.title {
  @apply font-normal highlight mb-6 text-3xl;
}

@screen md {
  .title {
    font-size: 2rem;
    line-height: 2.7rem;
  }
}

.title em {
  @apply font-serif font-bold tracking-wide;
}

.content p {
  @apply text-base highlight font-normal tracking-normal pr-2 mb-6;
  @apply md:w-auto md:pr-0;
}
