.ForgotPasswordForm {
  display: flex;
  flex-direction: column;
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 114px;
  font-weight: 300;
}

.ResetBtn {
  width: 100%;
  margin-top: 8px;
}

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px var(--border-gray-200);
  padding: 24px;
  font-weight: 300;
  margin-top: 24px;
}

.LoginBtn {
  margin-left: 8px;
}
