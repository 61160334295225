.datePicker :global(.rdp) {
  --rdp-cell-size: auto;
  --rdp-accent-color: none;
  --rdp-background-color: none;
  --rdp-accent-color-dark: none;
  --rdp-background-color-dark: none;
  --rdp-outline: none;
  --rdp-outline-selected: none;
}

.datePicker :global(.rdp) {
  @apply relative flex-row m-0;
}

.datePicker :global(.rdp:focus-visible),
.datePicker :global(.rdp:-moz-focusring) {
  @apply ring;
}

.datePicker :global(.rdp-table) {
  @apply mt-4 w-full;
}

.datePicker :global(.rdp-caption) {
  @apply mb-6 text-center select-none;
}

.datePicker :global(.rdp-caption_label) {
  @apply text-base highlight autoType600;
}

.datePicker :global(.rdp-nav button) {
  @apply mx-2 hover:text-gray-500;
}

.datePicker :global(.rdp-months) {
  @apply flex justify-center gap-8;
}

.datePicker :global(.rdp-month) {
  @apply w-full m-0;
  max-width: 21rem;
}

.datePicker :global(.rdp-head_cell) {
  @apply text-center text text-300 pb-4;
}

.datePicker :global(.rdp-cell) {
  @apply relative z-10;
  min-width: 48px;
}

.datePicker.smallDatePicker :global(.rdp-cell) {
  min-width: 32px;
}

.datePicker :global(.rdp-day) {
  @apply w-7 h-7 mx-auto my-1 text-gray-800 text text-300 text-center overflow-visible;
  z-index: 1;
  position: unset;
}

.datePicker.smallDatePicker :global(.rdp-day) {
  @apply my-0.5;
}

.datePicker :global(.rdp-day_selected),
.datePicker :global(.rdp-day_selected:focus-visible),
.datePicker :global(.rdp-day_selected:hover) {
  @apply text-gray-800;
}

/* Disabled dates */
.datePicker :global(.rdp-day_disabled) {
  @apply text-gray-500 line-through opacity-50 pointer-events-none;
}

/* Hover state (primary-bordered white circle) - not applied to from and to dates */
.hover {
  @apply relative;
}

.hover:after {
  @apply absolute bg-white border-2 border-gray-800 rounded-full w-7 h-7;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* Selected date has dark primary circle background */
.single,
.start,
.end {
  @apply relative border-none !text-primary-contrast;
}

.single:after,
.start:after,
.end:after {
  @apply absolute bg-gray-800 rounded-full w-7 h-7;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* Dates between from with light primary bg */
.interval,
.minimum {
  @apply relative border-none;
}

.interval:before,
.minimum:before {
  @apply absolute bg-neutral-20 h-7 pointer-events-none;
  content: '';
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}

/* Dates on edges are rounded*/
.intervalFirst:before,
.interval.start:not(.intervalLast):before,
.datePicker :global(.rdp-cell):first-of-type .interval:before,
.minimumFirst:before,
.minimum.start:not(.intervalLast):before,
.datePicker :global(.rdp-cell):first-of-type .minimum:before {
  @apply !rounded-none;
  left: 50%;
}

.intervalLast:before,
.interval.end:before,
.datePicker :global(.rdp-cell):last-of-type .interval:before,
.minimum.end:before,
.minimumLast:before,
.datePicker :global(.rdp-cell):last-of-type .minimum:before {
  @apply !rounded-none;
  right: 50%;
}

.minimumLast:not(.single, .start, .end, .hover):after,
.datePicker :global(.rdp-cell):first-of-type .interval:not(.single, .start, .end, .hover):after,
.datePicker :global(.rdp-cell):first-of-type .minimum:not(.single, .start, .end, .hover):after,
.datePicker :global(.rdp-cell):last-of-type .interval:not(.single, .start, .end, .hover):after,
.datePicker :global(.rdp-cell):last-of-type .minimum:not(.single, .start, .end, .hover):after {
  @apply absolute bg-neutral-20 rounded-full w-7 h-7 pointer-events-none;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.datePicker[data-stacked='true'] :global(.rdp-months) {
  @apply flex-col items-center;
}

.datePicker[data-stacked='true'] :global(.rdp-month) {
  @apply mt-6 mb-4;
}

.datePicker[data-stacked='true'] :global(.rdp-nav) {
  @apply hidden;
}

.datePicker[data-stacked='true'] :global(.rdp-day) {
  @apply my-2;
}
