/* Icon sizes */

.icon[data-size='small'] {
  @apply w-3 h-3;
}

.icon[data-size='normal'] {
  @apply w-5 h-5;
}

.icon[data-size='large'] {
  @apply w-7 h-7;
}
