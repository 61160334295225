#onetrust-group-container {
  @apply w-full md:!w-[66%] xl:!w-[72%];
}

#onetrust-accept-btn-handler, #onetrust-reject-all-handler, #onetrust-pc-btn-handler {
  @apply !rounded-full !p-2 !m-0 !border-2 !border-white !font-normal !text-500 !w-[152px] !h-[48px];
}

#onetrust-button-group {
  @apply !flex !justify-center !flex-row md:!flex-col lg:!flex-row !items-center md:!justify-end gap-3 !m-0 !w-full;
}

#onetrust-reject-all-handler, #onetrust-pc-btn-handler {
  @apply !mb-0 !bg-transparent;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  @apply !text-white !font-normal !text-500 !text-sm !no-underline !p-0 !pt-[1px];
  border: 1px solid #fff !important;
}

#onetrust-accept-btn-handler {
  @apply !bg-white !text-black !border-none;
}

div.ot-sdk-container {
  @apply !bg-gray-800 !text-black !border-none !w-full !p-5;
}

div.ot-sdk-row {
  @apply !flex !flex-col md:!flex-row !w-full !justify-between items-center !gap-2;
}

#onetrust-button-group-parent {
  @apply !p-0 !m-0 !flex !flex-wrap !relative md:!w-[25%] !inset-0 !translate-x-0 !translate-y-0;
}

#onetrust-policy {
  @apply !m-0 pr-5 lg:pr-0;
}

#onetrust-policy-text {
  @apply !text-white;
}

#onetrust-banner-sdk {
  @apply rounded-lg !w-[calc(100vw-30px)] md:!w-[calc(100vw-80px)] bottom-3 mx-auto mb-5 md:mb-7 !z-1299;
}

#onetrust-consent-sdk #onetrust-banner-sdk a[href] {
  @apply !text-green-100 !font-normal;
}

/* Center 'x' button on cookies icon */
button.ot-floating-button__close svg {
  @apply mx-auto my-auto;
}

#ot-sdk-btn-floating {
  @apply !bottom-6 !left-6;
}

#ot-sdk-btn-floating.ot-floating-button {
  @apply !w-14 !h-14;
}

/* X button */
#onetrust-banner-sdk #onetrust-close-btn-container button {
  @apply right-0 top-0 sm:top-0 sm:right-0 md:top-[-0px] md:right-[5px] lg:!top-[-23px] lg:right-[-28px] xl:!right-[-25px];
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
  #onetrust-banner-sdk #onetrust-close-btn-container button {
      right: -19px !important;
      top: -22px !important;
  }
}

@media only screen and (min-width: 897px) and (max-width: 992px) {
  #onetrust-banner-sdk #onetrust-close-btn-container button {
      right: -19px !important;
      top: -44px !important;
  }
}

@media only screen and (min-width: 1024px) {
  #onetrust-banner-sdk #onetrust-close-btn-container button {
      right: -22px !important;
  }
}

#onetrust-banner-sdk .onetrust-close-btn-ui {
  background-size: 15px !important;
}
