.stayLocation,
.stayTowable {
  @apply px-4 pb-2 m-4 mt-0 border border-gray-200 rounded-lg pt-[10px];
}

.stayLocation label,
.stayTowable label {
  @apply font-normal;
}

.location,
.towable {
  @apply p-4 border-b border-gray-200;
}

.stayDatesWrapper {
  @apply flex px-4 pb-2 m-4 mb-0 border border-gray-200 rounded-lg pt-[10px];
}

.datesWrapper {
  @apply flex w-full grid auto-cols-fr grid-flow-col;
}

.stayDates {
  @apply flex-1 border-r border-gray-200
}

.dates {
  @apply flex-1 p-4 border-r border-gray-200;
}

.stayGuests {
  @apply flex-1 pl-4;
}

.stayDates label,
.stayGuests label {
  @apply font-normal;
}

.guests {
  @apply flex-1 p-4;
}

.staySearch {
  @apply p-4 pb-0 md:pb-4;
}

.search {
  @apply p-4 border-t border-gray-200;
}
