.control::after {
  @apply transition duration-150 ease-in-out absolute block bg-white rounded-full transform translate-x-0;
  content: '';
}

.control[data-size='small']::after {
  @apply w-3 h-3 left-0.5
}

.control[data-size='auto']::after {
  @apply md:w-3 md:h-3 md:left-0.5
}

.control[data-size='large']::after {
  height: calc(1rem + 2px);
  left: 3px;
  width: calc(1rem + 2px);
}

.control[data-size='large']::after {
  @apply h-6;
  width: calc(3rem - 6px);
}

.input:focus-visible + .control::before {
  @apply absolute block border-2 border-green-500 rounded-full -inset-4;
  content: '';
}

.input:disabled + .control {
  @apply opacity-50 cursor-default;
}

.input:checked + .control {
  @apply bg-green-500;
}

.input:checked + .control::after {
  @apply transform translate-x-full;
}

@media (max-width: theme('screens.md')) {
  .control[data-size='auto'] {
    @apply h-6;
    width: calc(3rem - 6px);
  }

  .control[data-size='auto']::after {
    height: calc(1rem + 2px);
    left: 3px;
    width: calc(1rem + 2px);
  }
}
