.content ul {
  @apply list-disc ml-6;
}
.content ol {
  @apply list-decimal ml-6;
}

.content p,
.content span,
.content ul > li,
.content ol > li {
  @apply body-sm;
}

.content ul > li,
.content ol > li {
  @apply text-gray-900 mb-4;
}

.content ul > li li,
.content ol > li li {
  @apply mb-0;
}

.content p {
  @apply mb-6;
}
.categoriesContent :last-of-type {
  @apply mb-0;
}
.content p > b,
.content strong,
.content p > strong {
  font-weight: 400;
}
.content h1 {
  margin: 1rem 0;
  @apply h1 text-gray-900;
}
.content h1 {
  margin: 1rem 0;
  @apply h5 text-gray-900;
}
.content h2 {
  margin: 1rem 0;
  @apply h2 text-gray-900;
}
.content h3 {
  margin: 1rem 0;
  @apply h3 text-gray-900;
}
.content h4 {
  margin: 1rem 0;
  @apply h4 text-gray-900;
}
.content h5 {
  margin: 1rem 0;
  @apply h5 text-gray-900;
}
.content h6 {
  margin: 1rem 0;
  @apply h6 text-gray-900;
}

.content iframe {
  @apply max-w-full;
}
.content a {
  @apply border-b border-green-400;
}

@screen md {
  .content {
    max-height: 9rem;
  }
}
