.inputContainer {
  @apply relative flex flex-wrap items-center font-sans transition-colors duration-100 ease-linear bg-white border border-gray-200 rounded autoType300 cursor-text;
}
.inputContainer:hover {
  @apply border-gray-800;
}

.inputContainer[data-focused='true'] {
  @apply border-primary-800;
}
.inputContainer[data-has-error='true'] {
  @apply border-red-800;
}
.inputContainer[data-read-only='true'] {
  @apply text-gray-500 cursor-not-allowed pointer-events-none;
}
.inputContainer[data-has-error='true'] .label,
.inputContainer[data-has-error='true'] .input,
.inputContainer[data-has-error='true'] .input:-webkit-autofill::first-line {
  @apply text-red-800;
}

.inputContainer[data-read-only='true'] .input {
  @apply text-gray-500;
}

.label {
  @apply absolute z-20 px-1 overflow-hidden text-gray-500 whitespace-nowrap duration-100 ease-linear transform translate-x-3;
  background-color: inherit;
  max-width: calc(100% - 2rem);
  text-overflow: ellipsis;
  transition-property: transform, font-size, color;
  z-index: 10;
}

.label:before {
  content: '';
  @apply absolute left-0 w-full px-1 bg-white;
  z-index: -1;
  bottom: 6px;
  height: 1px;
}

.inputContainer[data-focused='true'] .label,
.inputContainer[data-empty='false'] .label {
  @apply autoType200 -translate-y-7;
}
/*
 * Since we're targetting a vendor specific pseudo class we need to keep it
 * separate so it doesn't invalidate this declaration for the other selectors.
 */
.inputContainer .input:-webkit-autofill + .label {
  @apply autoType200 -translate-y-7;
}
.inputContainer[data-has-error='false'][data-focused='true'] .label,
.inputContainer[data-has-error='false'][data-empty='false']:hover .label {
  @apply text-gray-800;
}

.input {
  @apply relative z-10 w-full p-4 text-gray-900 bg-transparent outline-none;
}
.input:-webkit-autofill {
  appearance: none;
  transition: background-color 0s ease-in-out 1e9s;
}
.input:-webkit-autofill::first-line {
  @apply font-sans text-gray-900 autoType300;
}

.error {
  @apply mt-1 text-red-800 text autoType200;
}

.input:disabled {
  cursor: not-allowed;
}

.lock {
  @apply absolute right-4;
}
