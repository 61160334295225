.toggle {
  @apply w-full;
}

.toggle > span:first-of-type {
  @apply m-0;

  min-width: 1.75rem;
}

.toggleText {
  @apply flex items-center mr-auto;
}

.toggleText svg {
  min-width: calc(1rem + 2px);
}

.toggleText p {
  @apply mr-2;
}
