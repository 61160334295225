.fullUniversalSearch {
  @apply bg-white rounded-[14px] pb-6 bg-bottom bg-cover;
  @apply md:px-8 md:py-6;
}

.withoutTabListMenu {
  @apply md:pt-4 md:pb-10;
}

.withoutTabListMenu .categoryTabs {
  @apply rounded-t-[14px] md:rounded-none;
}
