.container {
  @apply relative z-50 flex bg-white rounded-t-[10px] drop-shadow-xl md:rounded-t-[14px];
}

.tab_list {
  @apply grid grid-flow-col whitespace-nowrap auto-cols-min m-auto mb-0 list-none relative px-2 overflow-auto;
  @apply md:flex md:flex-row md:pb-0 md:px-0;
}

.header_tab {
  @apply flex flex-col items-center box-content py-3 px-1 mx-2 text-center text-sm text-gray-800 semiHighlight font-medium;
  @apply md:text-base md:flex-row;
}

.border_bottom {
  bottom: 0;
  height: 3px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @apply bg-gray-900;
}

/* stays filter tabs design */
.stayMenu {
  @apply md:-mx-8 md:-mt-6;
}

.stayMenu .header_tab {
  @apply h-15 box-border px-3 grid grid-flow-col gap-2 text-base;
  @apply md:flex md:gap-0;
}

.stayMenu .header_tab svg {
  @apply mb-0;
}

.stayMenu .border_bottom {
  @apply bg-green-500;
}

.secondary {
  @apply bg-white pt-6 pb-1 relative;
  @apply md:pb-6;
}

.secondary .tab_list {
  @apply pb-4;
  @apply md:pb-0;
}

.secondary .header_tab {
  @apply h-8 rounded-2xl box-border border-[1.5px] border-grey-200 px-3 py-0 text-gray-850;
  @apply font-normal text-sm justify-center;
  @apply md:mb-0;
}

.secondary .border_bottom {
  @apply bg-transparent h-8 box-border border border-gray-850 rounded-2xl;
}

.secondary.filters_container {
  @apply md:flex md:justify-between;
}

.secondary.filters_container .tab_list {
  @apply m-0;
}

.secondary .filters {
  @apply py-2 px-4;
  @apply md:py-0 md:px-0;
}
