.link:not([data-on-transparent-header='true'])[data-active='true'] {
  @apply text-gray-800;
}

.link[data-active='true'] {
  @apply underline;
}

@screen xl {
  .link {
    @apply relative;
  }

  .link:not([data-on-transparent-header='true'])[data-active='true'] {
    @apply text-gray-900;
  }

  .link[data-active='true'] {
    @apply no-underline;
  }

  .link[data-active='true'] .linkCaret {
    @apply rotate-90;
  }

  .linkCaret[data-has-submenu='false'] {
    @apply hidden;
  }

  .link[data-desktop-button='true'] {
    @apply px-6 py-4 rounded-full autoType400 text-gray-850 border-2 border-gray-850 border-solid highlight;
  }

  .link[data-desktop-button='true']:before {
    @apply rounded-md -inset-6 !important;
  }
}

.newNavMenuItem {
  @apply text autoType300 md:autoType300 lg:ml-4 lg:p-0 xl:ml-6;
}

.newNavMenuItem .link {
  @apply no-underline;
}

.newNavMenuItem .linkCaret {
  @apply mb-0 ml-1 w-3;
}

.newNavMenuItem .linkCaret[data-has-submenu='false'] {
  @apply hidden;
}

.newNavMenuItem .link[data-desktop-button='true'] {
  @apply px-6 py-4 rounded-full autoType400 text-gray-850 border-2 border-gray-850 border-solid highlight;
  @apply block text-center;
}
