.towableLocationTab {
  @apply border-b border-neutral-20;
  flex: 0 0 100%;
}

.locationTab {
  @apply md:border-b border-neutral-20 lg:border-none w-full lg:w-auto grow-[3];
}

.homeInputContainer {
  @apply relative flex flex-row items-center w-full h-full m-0 px-4;
  @apply md:px-6;
}

.homeInputContainer::before {
  @apply hidden absolute left-5 bottom-0 h-0.5 bg-gray-50;
  content: '';
  width: calc(100% - 34px);
}

.homeInputContainer[data-focus='true']::before {
  @apply block;
}

.homeInput,
.homeInput + span {
  @apply w-full pt-5 font-medium text-left text-gray-900 bg-transparent highlight text-ellipsis;
  height: 4.25rem;
  font-size: 16px;
}

.homeInput::placeholder {
  @apply text-transparent;
}

.homeInput + span {
  @apply absolute flex items-center left-4 top-0 pointer-events-none;
  @apply md:left-6;
  font-size: 16px;
}

.homeInput:focus + span {
  @apply hidden;
}

.homeInput:focus::placeholder {
  @apply text-gray-310;
}

@screen md {
  .homeInput,
  .homeInput + span {
    height: 5.5rem;
    font-size: 19px;
  }
}
