/* .search_button {
  @apply flex items-center !important;
  @apply h-10 !important;
  @apply px-3 !important;
  border-radius: 5px !important;
} */

.search_wrapper {
  @apply grid grid-flow-col;

  grid-template-columns: repeat(2, 1fr) min-content;
}

.search_wrapper.isTowable {
  grid-template-columns: repeat(3, minmax(auto, 1fr)) min-content;
}

.search_wrapper.isTowable .search_location {
  grid-column: 1 / 5;
}

.search_wrapper.isTowable > button,
.search_wrapper.isTowable .searchBtnWrapper {
  @apply row-start-2;
}

@screen lg {
  .search_wrapper {
    @apply grid-flow-col;

    grid-template-columns: 3fr 2fr 2fr min-content;
  }
}

.search_location {
  grid-column: 1 / 5;
}

@screen lg {
  .search_location {
    grid-column: 1 / 2;
  }
}

.date_tab {
  @apply flex items-center justify-center px-4 text-base border-l border-neutral-20 cursor-pointer text-secondary-base semiHighlight;
}

.search_filter {
  @apply absolute border rounded-lg border-neutral-20;
  @apply top-4;
  @apply xxxl:top-7;
}

.search_homepage {
  @apply bg-transparent relative;
}

.active_location_tab {
  @apply rounded-bl-none border-b border-neutral-20;
}

.homeDateButton,
.homeGuestButton,
.homeVehicleButton,
.homeDetailsButton {
  @apply relative px-4 pt-5 font-medium text-gray-900 highlight justify-start px-6;
  font-size: 1.1875rem;
}

.homeDateButton,
.homeGuestButton,
.homeDetailsButton,
.homeVehicleButton {
  @apply row-start-2 text-left leading-5 block overflow-hidden text-ellipsis whitespace-nowrap;
  @apply lg:row-start-1;
}

.homeDateButton[data-tab='DATES'],
.homeDateButton[data-tab='DETAILS'],
.homeVehicleButton[data-tab='VEHICLE'],
.homeVehicleButton[data-tab='DATES'],
.homeVehicleButton[data-tab='DETAILS'] {
  @apply rounded-bl-none;
}

.homeVehicleButton {
  flex: 7;
  overflow: hidden;
}

.homeVehicleButton span:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .homeSearchButton {
  width: 120px;
  @apply h-14 !important;
} */

.homeLabel {
  @apply absolute text-xs font-medium tracking-wide uppercase;
  @apply top-4 left-4 right-4 highlight text-gray-50 overflow-hidden text-ellipsis whitespace-nowrap;
  @apply md:top-5 md:left-6;
}

.homeDateWrapper {
  @apply mx-auto md:p-6;
}

.homeDateWrapper > div:first-child {
  @apply mx-auto;
  max-width: 44rem;
}

.homeDetailsWrapper {
  @apply md:p-6;
}

.searchDropdown {
  @apply shadow-xl absolute left-0 right-0 top-full z-10 rounded-b-[14px] overflow-hidden;
}

.searchBtnWrapper {
  @apply p-4 border-t border-neutral-20 rounded-bl-2xl row-start-2;
  @apply md:rounded-bl-none;
  @apply lg:row-start-1;
}

@screen md {
  .homeDetailsWrapper > div:first-child > div > span > label {
    max-width: 16rem;
  }

  .searchDropdown[data-tab='LOCATION'] {
    margin-top: -5.5rem;
  }

  .searchBtnWrapper {
    @apply border-0 pl-0;
    flex-basis: 0;
  }
}

@screen lg {
  .searchDropdown[data-tab='LOCATION'] {
    @apply mt-0;
  }

  .searchDropdown[data-tab='LOCATION'][data-istowable='true'] {
    margin-top: -5.5rem;
  }
}

.search_homepage.hasStayFilter {
  @apply bg-white rounded-2xl border border-gray-310;
}
