.select {
  @apply absolute w-full opacity-0 cursor-pointer;
  height: 3.375rem; /* Make element's height the same as the label */
}

.label {
  @apply flex items-center justify-between w-full p-4 border border-gray-200 border-solid pointer-events-none rounded;
}

.select:focus + .label {
  @apply border-primary-800;
}

.select:hover + .label {
  @apply border-gray-800;
}

.select[data-has-error='true'] + .label {
  @apply border-red-800;
}

.select[data-has-error='true'] + .label * {
  @apply text-red-800;
}

.error {
  @apply mt-1 text-red-800 autoType200;
}
