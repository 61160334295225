.container {
  width: 100%;
  max-height: calc(100% - theme('spacing.4'));
}

.container[data-scrolled='true'] > .titleContainer {
  @apply border-gray-200;
}

.closeButton:focus-visible::after {
  @apply absolute inset-0 block border-2 border-primary-800;
  content: '';
}

@screen md {
  .container {
    max-width: 42.5rem;
    max-height: calc(100% - (theme('spacing.20') * 2));
  }

  .container[data-size='medium'] {
    max-width: 32.5rem;
  }
}
