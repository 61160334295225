@import 'tailwindcss/tailwind';
@import '../components/switchback/Carousel/Slick.css';
@import '../components/OneTrust/OneTrust.css';

.aligncenter > img {
  margin: 0 auto;
}

body {
  /* Colors */
  --border-gray-200: rgba(229, 231, 235);
  @apply antialiased;
}

@layer utilities {
  .gap-x-default {
    column-gap: 1rem;
  }
  @screen xxxl {
    .gap-x-default {
      column-gap: 1.25rem;
    }
  }

  :root {
    --grid-col-offset: 0;
  }

  .grid-cols-main {
    --grid-col-offset: 1;
  }

  .grid-cols-main > .grid > *,
  .grid-cols-main > .xs\:grid > *,
  .grid-cols-main > .sm\:grid > *,
  .grid-cols-main > .md\:grid > *,
  .grid-cols-main > .lg\:grid > *,
  .grid-cols-main > .xl\:grid > *,
  .grid-cols-main > .xxl\:grid > *,
  .grid-cols-main > .xxxl\:grid > * {
    --grid-col-offset: 0;
  }

  .grid-cols-main {
    grid-template-columns: [container-start] 0 [content-start] repeat(6, minmax(0, 1fr)) [content-end] 0 [container-end];
  }
  @screen md {
    .grid-cols-main {
      grid-template-columns: [container-start] 0 [content-start] repeat(10, minmax(0, 1fr)) [content-end] 0 [container-end];
    }
  }
  @screen lg {
    .grid-cols-main {
      /* 82rem = 80rem (1260px) + 2rem (2 gaps) */
      grid-template-columns:
        [container-start] calc((100% - 82rem) / 2) [content-start] repeat(16, minmax(0, 1fr))
        [content-end] calc((100% - 82rem) / 2) [container-end];
    }
  }
  @screen xxxl {
    .grid-cols-main {
      /* 4.375rem = 70px => 1440px (container) - 140px (padding cols) - 40px (gaps) = 1260px */
      grid-template-columns: [container-start] 4.375rem [content-start] repeat(16, minmax(0, 1fr)) [content-end] 4.375rem [container-end];
    }
  }

  .container-main {
    margin-left: auto;
    margin-right: auto;
    max-width: 90rem;
  }
}

@layer components {
  .before-focus-style:focus-visible:before {
    @apply absolute block border-2 border-green-800 -inset-4;
    content: '';
  }
}

/* purgecss start ignore */
/* This is a header style hack since our styles are mis-ordered */
@media (min-width: 1024px) {
  div[data-feature='search'] {
    max-width: 20rem !important;
  }
}

a {
  @apply text-gray-800;
}

.js-focus-visible :focus {
  outline: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* custom Cloudsponge widget styles*/
#cloudsponge-address-book .cloudsponge-topbar,
#cloudsponge-address-book #cloudsponge-export-button,
#cloudsponge-address-book .cloudsponge-header {
  @apply bg-gray-800 !important;
}

#cloudsponge-address-book
  ul.cloudsponge-contacts-list
  > li
  input[type='checkbox']:checked
  + label:before {
  @apply text-gray-800 !important;
}

body {
  @apply bg-white text-gray-850;
}
/* purgecss end ignore */

/* Hides GTM help menu, since we render our own */
.help-container {
  @apply hidden;
}

.help-menu-body > li {
  @apply text-gray-800;
}

.help-menu-body > li > svg > path {
  @apply fill-gray-800;
}

.section-spacing {
  @apply px-4 md:py-24 py-20 mx-auto max-w-xxl;
}
